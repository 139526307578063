import React from 'react';
import Lottie from 'lottie-react';
import SuccessAnimation from '../assets/success.json'
import { Link } from 'react-router-dom';

function Paysuccess() {

  return (
    <div className='flex w-full justify-center items-center'>
      <div className='flex flex-col items-center my-16 mx-3'>
        <div className='max-w-64'>
            <Lottie animationData={SuccessAnimation} />
        </div>
        <div>
            <h1 className='my-6 text-4xl lg:text-6xl'>Payment Successful</h1>
        </div>
        <p className='my-4 w-fit text-xl text-center'>Payment successfully completed! Thank you for making the purchase</p>
        <Link to='/'>
            <button className='bg-yellow-200 text-xl p-3 min-w-64 m-16 rounded-xl'>Go Back</button>
        </Link>
      </div>
    </div>
  );
}

export default Paysuccess;