import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './components/main';
import Header from './components/header';
import Footer from './components/footer';
import Payfail from './components/payfail';
import Paysuccess from './components/paysuccess';


function App() {
  return (
    <Router>
      <div className="App w-full min-h-screen">
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/payment-failed" element={<Payfail />} />
          <Route path="/payment-successful" element={<Paysuccess />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
