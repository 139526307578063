import React from 'react'
import logo from  '../assets/enticelogo.png'

function Header() {
  return (
    <div className='flex justify-center border-b py-5 sticky'>
        <img className='md:w-20 lg:w-40 sm:w-20 w-20' alt='entice logo' src={logo}/>
    </div>
  )
}

export default Header