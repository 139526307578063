import React, { useState } from 'react'
import image from '../assets/instagramarketing.png'
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe('pk_live_51JlpXlSDjhoraR7GvjnTLbkhHPKgNdROGeq1TUtiOoME5Km8rZ6QJQhvmImPKVkgGMz89869hE1q0QSaBAVHP7Pl00Ge9YQCOf');


function Main() {
  // State to store the selected package
  const [selectedPackage, setSelectedPackage] = useState('p1');

  const packages = ([
    {'text': 'Standard Package - $25', 'id': 'p1', 'amount': 25, 'price_id': 'price_1PScJfSDjhoraR7GKLQruvkb'},
    {'text': 'Starter Package - $39', 'id': 'p2', 'amount': 39, 'price_id': 'price_1PScJfSDjhoraR7G14kMSBPN'},
    {'text': 'Launch Package - $59', 'id': 'p3', 'amount': 59, 'price_id': 'price_1PScJfSDjhoraR7GS2s3BFro'},
    {'text': 'Professional Package - $89', 'id': 'p4', 'amount': 89, 'price_id': 'price_1PScJfSDjhoraR7GQ4bsC1HI'},
    {'text': 'Elite Package - $169', 'id': 'p5', 'amount': 169, 'price_id': 'price_1PScJfSDjhoraR7GOCh3cL0D'},
    {'text': 'Top Tier Package - $289', 'id': 'p6', 'amount': 289, 'price_id': 'price_1PScJfSDjhoraR7GOlZKtkft'},
    {'text': 'Legendary Package - $449', 'id': 'p7', 'amount': 449, 'price_id': 'price_1PScJfSDjhoraR7GrrrZlCSA'}
  ])

  // Function to handle button click
  const handlePackageClick = (packageName) => {
    setSelectedPackage(packageName);
  };

  const handleStripeCheckout = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise;
    const price_id = packages.find(pkg => pkg.id === selectedPackage).price_id;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: price_id, // Replace with the ID of your price
        quantity: 1,
      }],
      mode: 'payment',
      successUrl: 'https://enticemodels.com/payment-successful',
      cancelUrl: 'https://enticemodels.com/payment-failed',
    });}

  const getPrice = () => {
    const selected = packages.find(pkg => pkg.id === selectedPackage);
    return selected ? selected.amount : 25;
  }

  return (
    <div className='w-full flex justify-center my-16'>
      <div className=' flex flex-wrap'>
        <div className=''>
          <div className='w-auto image-container p-6'>
            <img className='rounded' alt='IMimage' src={image}/>
          </div>
        </div>
        <div className='flex flex-col flex-shrink justify-evenly product-content p-6'>
          <div className='my-3'>
            <p className='text-gray-600'>ENTICE</p>
            <h1 className='lg:text-6xl text-4xl'>Instagram Promotion</h1>
          </div>
          <p className='text-2xl my-5'>${getPrice()}.00 USD</p>
          <div className='flex flex-col font-medium my-3'>
            <p className='text-xl text-gray-600'>Package</p>
            {packages.map((packageName) => (
              <button
                key={packageName.id}
                className={`border rounded-xl border-black py-2 px-5 my-2 w-fit tracking-wider ${
                  selectedPackage === packageName.id ? 'bg-black text-white' : 'hover:bg-black hover:text-white'
                }`}
                onClick={() => handlePackageClick(packageName.id)}
              >
                {packageName.text}
              </button>
            ))}
          </div>
          <div className='my-3 flex flex-col'>
            <button role="link" onClick={handleStripeCheckout} className='rounded-xl bg-yellow-200 py-3 px-5 my-2 tracking-wider font-medium text-lg antialiased transition-transform duration-100 ease-in-out transform hover:scale-110'>Buy</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
