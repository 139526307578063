import React from 'react'
import visasvg from '../assets/visa.svg'
import amexsvg from '../assets/amex.svg'
import mastercardsvg from '../assets/mastercard.svg'
import discoversvg from '../assets/discover.svg'

function Footer() {
  return (
    <div className='w-full flex justify-evenly border-t py-10 px-2'>
      <div>
        © 2024, Entice Models. All rights reserved
      </div>
      <div className='flex'>
        <img className='max-w-10 border mx-2 h-fit' alt='visa svg' src={visasvg} />
        <img className='max-w-10 border mx-2 h-fit' alt='amex svg' src={amexsvg} />
        <img className='max-w-10 border mx-2 h-fit' alt='mastercard svg'  src={mastercardsvg} />
        <img className='max-w-10 border mx-2 h-fit' alt='discover svg' src={discoversvg} />
      </div>
    </div>
  )
}

export default Footer